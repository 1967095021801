import React, { useState } from "react";
import "../styles/Timebar.css";
import TimeButton from "./TimeButton";

export default function Timebar({ setType }) {
  const [active, setActive] = useState("Today");

  return (
    <div className="timebar-container">
      <TimeButton
        title={"Yestarday"}
        active={active}
        setActive={setActive}
        setType={setType}
      />
      <TimeButton
        title={"Today"}
        active={active}
        setActive={setActive}
        setType={setType}
      />
      <TimeButton
        title={"Tomorrow"}
        active={active}
        setActive={setActive}
        setType={setType}
      />
    </div>
  );
}
