import React from 'react'
import '../styles/ListItem.css'
import { useNavigate } from 'react-router-dom';


export default function ListItem({data}) {

    const navigate = useNavigate();

    const playHandler = () => {
        
        navigate('/video', { state: { url: data.id } });
        
    }

    return (
        <div className='item-list' onClick={playHandler}>
            <div className='team home'>
                <p className='item-title'>{data.homeTeam}</p>
                <img className='item-logo' src={data.homeTeamLogo} alt='logo' />
            </div>  
             <p  className='item-hyphen'> - </p>
            <div className='team away'>
            <img className='item-logo' src={data.awayTeamLogo} alt='logo' />
                <p className='item-title'>{data.awayTeam}</p>
            
            </div>   

        </div>
    )
}


// Compare this snippet from src/components/ListItem.css:



