import * as React from "react";
import Button from "@mui/material/Button";

export default function TimeButton({ title, setType, active, setActive }) {
  const clickHandler = (e) => {
    setActive(title);
    if (title === "Yestarday") {
      setType("1");
    }

    if (title === "Today") {
      setType("2");
    }
    if (title === "Tomorrow") {
      setType("3");
    }
  };

  return (
    <Button
      onClick={clickHandler}
      variant="contained"
      color={active === title ? "primary" : "secondary"}
      size="medium"
    >
      {title}
    </Button>
  );
}
