import Timebar from '../components/Timebar';
import ListItem from '../components/ListItem';
import React, { useEffect, useState, } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';





function HomePage () {
  
  const [dataList, setDataList] = useState([]);
  const [type, setType] = useState('2');


  useEffect(() => {
    const fetchData =  async() => {
       const url = `https://live.football-live-tv.com/game?id=${type}`;
        const options = {
          method: 'GET',
          headers: {
            'X-Auth-Token': 'e2b5f1d7b9a64f9a8c1c5a7d4a3f0d8b'
          }
        }
        const response = await axios.get(url, options);
        setDataList(response.data);
        console.log(response.data);
    }
    fetchData();

  }, [type]);




  return (
    <>
    <Helmet>
      <title>Football Live</title>
      <meta name="Watch Live Football Matches" content="Football Live" />
      <meta name= "keywords" content="football, live, matches, watch, primer league, champion, laliga, highlights, replay" />
      <meta name= "robots" content="index, follow" />
      <link rel="canonical" href="/" />
    </Helmet>
    <div className='app-container'>
    <div className="App">
    </div>
   <Timebar  setType={setType} />
 
    {
      dataList.length > 0 &&
      dataList.map((item, index) => {
        return <ListItem key={index} data={item}  />
      })
   }

   {
      dataList.length === 0 &&
      <div className='loading-container'>
        <h1 className='loading-title'>Loading...</h1>
      </div>
   }
   </div>
   </>

  )}







export default HomePage;