import React from "react";
import { Helmet } from "react-helmet-async";
import VidyardPlayer from "react-player";
import { useLocation } from "react-router-dom";

export default function VideoPage() {

  const location = useLocation();
 const url = `https://live.football-live-tv.com/1890/${location.state.url}.m3u8`

  return (
    <div style={{
      marginTop: '30px',
    }}>
      <Helmet>
        <title>Football Live</title>
        <meta name="Watch Live Football Matches" content="Football Live" />
        <meta name="keywords" content="football, live, matches, watch, primer league, champion, laliga" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="/video" />
      </Helmet>
      <VidyardPlayer
        url={url}
        playing
        controls
        width="100%"
        height="50%"
      />
    </div>
  );
}


