import './App.css';
import Timebar from './components/Timebar';
import ListItem from './components/ListItem';
import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import ReactPlayer from 'react-player';
import HomePage from './pages/Home';
import VideoPage from './pages/Video';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NavBar from './Header/NavBar';




function App() {
  
 


  


  return (
    <div className='app-container'>
      <BrowserRouter>
        <NavBar />
        <Routes>
          <Route path='/' element={<HomePage />} />
          <Route path='/video' element={<VideoPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  )
    
}

export default App;


